<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="结算单号">
              <a-input v-model="queryParam.serial_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报价编号">
              <a-input v-model.trim="queryParam.quota_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model.trim="queryParam.invoice_no"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="接单日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['order_kind']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in orderKindOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="审核状态">
              <a-select
                allowClear
                placeholder="请选择审核状态"
                style="width: 100%"
                v-model="queryParam.reviewed_status"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="item in reviewedStatusData" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="审核日期">
              <a-range-picker @change="reviewedDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票日期">
              <a-range-picker @change="openDateChange" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" v-perms="'ITMS_BALANCE_QUERY'" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length"
                @click="handleReviewed('basic_audit')" v-perms="'ITMS_BALANCE_REVIEWED'">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length"
                @click="handleReviewed('undo_audit')" v-perms="'ITMS_BALANCE_UNDO_REVIEWED'">反审核</a-button>
      <a-button type="primary" @click="exportSheet">导出</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length"
                @click="handleExportInvoices()">导出开票信息</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :scroll="{ x: 'max-content' }"
      :data="loadData"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="printDebitSheet(record, index)">查看</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a v-if="record.reviewed_status === 1" @click="handleBillApply(record, index)">开票申请</a>
          <a-divider type="vertical" v-if="record.reviewed_status === 1"/>
          <template v-if="record.audit_status !== 6 && (typeof record.audit_status !== 'undefined')">
            <a @click="updateAuditRemark(record, index)">修改备注</a>
            <a-divider type="vertical" />
          </template>
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
      <span slot="inner_serial" slot-scope="text, record, index">{{ '#' + (index + 1 ) }}</span>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '待提交' }}
        </a-tag>
      </div>
      <div slot="invoiceFeeItem" slot-scope="text, record">
        <a-popover placement="right">
          <template v-if="text && text.length > 0">
            <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})单 ` : null }}</span>
          </template>
          <template slot="content">
            <div v-for="item in record.fees" :key="item.id">
              <span v-if="item.pay_target_name">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
              <span v-else>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
            </div>
          </template>
        </a-popover>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.invoices" :key="item.id">
          <span v-if="item.invoice_type_code==='026'||item.invoice_type_code==='028'||item.invoice_type_code==='01'||item.invoice_type_code==='02'">
            发票号：
            <a v-if="item.void_status === 0 && item.is_download === '1'" style="color: red" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            <span v-if="item.void_status === 1">{{ item.invoice_no }}<a-tag color="red">失效</a-tag></span>
            <a v-if="item.void_status === 0 && item.is_download === '0'" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
          </span>
          <span v-else>发票号：{{ item.invoice_no }}</span>
        </div>
      </div>
      <div slot="fmsLogsItem" slot-scope="text, record">
        <div v-for="item in record.fms_capital_claim_logs" :key="item.id">
          <span>认领金额：{{ item.claim_amount }}认领人：{{ item.claim_ant_name }}</span>
        </div>
      </div>
      <span slot="reviewedInfo" slot-scope="text">
        <a-tag :color="text === 0 ? 'orange' : 'green'">
          {{ text === 0 ? '未审核' : '已审核' }}
        </a-tag>
      </span>
      <span slot="quotaNumsSlot" slot-scope="text">
        <a-popover placement="right">
          <template v-if="text && text.length > 0">
            <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})单 ` : null }}</span>
            <span>{{ text[0] }}</span>
          </template>
          <template slot="content">
            <span v-for="(item, i) in text" :key="i" style="display:block">{{ item }}</span>
          </template>
        </a-popover>
      </span>
      <span slot="receivingDateSlot" slot-scope="text">
        <a-popover placement="right">
          <template v-if="text && text.length > 0">
            <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length}) ` : null }}</span>
            <span>{{ moment(text[0]).format('YYYY/MM/DD') }}</span>
          </template>
          <template slot="content">
            <span v-for="(item, i) in text" :key="i" style="display:block">{{ moment(item).format('YYYY/MM/DD') }}</span>
          </template>
        </a-popover>
      </span>
      <span slot="remarkSlot" slot-scope="text">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 60">
            <span>{{ text.slice(0,60)+(text.length > 60?'...':'') }}</span>
          </template>
          <template v-else>
            <span>{{ text }}</span>
          </template>
          <template slot="content">
            <span style="display:block">{{ text }}</span>
          </template>
        </a-popover>
      </span>
    </s-table>
    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceConfirmLoading"
      title="开票申请"
      :width="980"
      @cancel="applyInvoiceCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="24">
          <div>
            <!-- <a-divider orientation="left">订单信息</a-divider> -->
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-input v-model="bizAuditForm.customer" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="所属部门">
                <a-input v-model="bizAuditForm.department" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="录入人">
                <a-input v-model="bizAuditForm.creator" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="申请时间">
                <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-divider orientation="left"
          >费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <!--          <a-checkbox-->
          <!--            :indeterminate="indeterminate"-->
          <!--            :checked="checkAll"-->
          <!--            @change="onCheckAllChange"-->
          <!--            style="padding-left:10px;padding-bottom:10px;"-->
          <!--          >-->
          <!--            全选-->
          <!--          </a-checkbox>-->
          <a-col :span="24">
            <!--            <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">-->
            <a-row v-for="item in bizAuditForm.fees" :key="item.id">
              <a-alert
                :message="`业务编号:${item.quotaNum}`"
                type="info"
                show-icon
                style="margin-bottom:10px;"
              />
              <a-col
                :span="8"
                v-for="fee in item.feeList"
                :key="fee.id"
                style="padding-left:15px;padding-bottom:10px;"
              >
                <span>{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span>
                <!--                <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>-->
              </a-col>
            </a-row>
            <!--            </a-checkbox-group>-->
          </a-col>
          <div>
            <a-col :span="24">
              <a-form-model-item label="申请备注">
                <a-input v-model="applyInvoiceForm.apply_remark"></a-input>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">开票信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位/客户名称" prop="customer_name">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo2"
                  :filter-option="false"
                  :not-found-content="fetching2 ? undefined : null"
                  @search="getDepartmentCustomer2"
                  @change="changeCustomerInfo2"
                >
                  <template v-if="fetching2" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerInfoOps2" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="tax_no">
                <a-input v-model="applyInvoiceForm.tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址">
                <a-input v-model="applyInvoiceForm.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话">
                <a-input v-model="applyInvoiceForm.mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-select v-model="applyInvoiceForm.bank_card_no" @change="bankAccountChange">
                  <a-select-option
                    :value="item.account"
                    v-for="item in bankAccountOps"
                    :key="item.account">
                    {{ item.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户银行">
                <a-input v-model="applyInvoiceForm.bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="发票类型" prop="invoice_type">
                <a-select v-model="applyInvoiceForm.invoice_type" @change="changeInvoiceType">
                  <a-select-option :value="item.value" v-for="item in invoiceTypeList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-select v-model="applyInvoiceForm.tax_rate">
                  <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="是否多票">
                <a-select v-model="applyInvoiceForm.many_together">
                  <a-select-option :value="item.value" v-for="item in manyTogetherList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <edit-table
                :columns="invoiceSubjectColumns"
                :sourceData="invoiceSubjectData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getInvoiceSubjectTableDate"></edit-table>
            </a-col>
            <a-col :span="24">
              <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="开票备注">
                <a-input v-model="applyInvoiceForm.invoice_remark"></a-input>
              </a-form-model-item>
            </a-col>
          </div>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="businessAuditFormVisible"
      :confirmLoading="businessAuditConfirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="billOrverifyCancel"
      @ok="billOrVerifySubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <div>
          <a-divider orientation="left">基础信息</a-divider>
          <a-form-model-item label="付款方式" prop="pay_method">
            <a-select v-model="form.pay_method">
              <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item label="附件纸" prop="file_paper">
            <a-input suffix="张" v-model="form.file_paper"/>
          </a-form-model-item> -->
          <a-form-model-item label="发票号码" prop="invoice_number">
            <a-select
              showSearch
              mode="multiple"
              ref="invoice_number"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择发票号码"
              style="width: 100%"
              v-model="form.invoice_num"
            >
              <a-select-option v-for="op in invoiceRegOps" :key="op.id"
              >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </div>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          style="padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-row>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-row v-for="item in feeOrderList" :key="item.id">
              <a-alert :message="`订单编号:${item.quotaNum}`" type="info" show-icon style="margin-bottom:10px;" />
              <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
                <a-checkbox :value="fee.id">{{ fee.pay_target_name }} {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-row>
        <div>
          <a-form-model-item label="申请备注" prop="apply_remark">
            <a-textarea v-model="form.apply_remark"></a-textarea>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="blanceFeeVisible"
      :confirmLoading="blanceFeeLoading"
      title="编辑结算单"
      :width="1200"
      @cancel="blanceFeeCancel"
      @ok="blanceFeeSubmit"
    >
      <!--      已经保存到结算单的费用-->
      <a-card title="结算单费用" style="margin-bottom: 10px">
        <div class="balanceTitle">报价编号:{{ balanceRecord.quotaNumArr }}</div>
        <a-row>
          <a-col :span="24">
            <div class="balanceTitle">应付费用</div>
            <div v-for="(item, index) in balanceRecord.pay_fees" :key="item.id">{{ item.fee_name }}/{{ item.pay_target_name }}/{{ item.money }}/{{ item.currency_name }}<a-button type="link" @click="delBalanceFee(index,'pay')">删除</a-button></div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="订单费用">
        <a-checkbox-group @change="balanceFeeChange" v-model="balanceFeeIds" style="width: 100%">
          <a-row v-for="item in balanceData" :key="item.id">
            <div class="balanceTitle">报价编号:{{ item.quota_num }}</div>
            <a-col :span="24">
              <div class="balanceTitle">应付费用</div>
              <a-checkbox v-for="fee in item.pay_fees" :key="fee.id" :value="fee.id">
                {{ fee.fee_name }}/{{ fee.pay_target_name }}/{{ fee.money }}/{{ fee.currency_name }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-card>
    </a-modal>
    <a-modal
      :visible="printItmsBillModel"
      :confirmLoading="printItmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印国贸结算单"
      @cancel="printItmsBillModel = false"
    >
      <div id="printItmsBill">
        <itms-bill
          :currentData="currentData"
          :balanceData="balanceBillData"
        ></itms-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printItmsBillBtn"
                  v-print="printItmsBill">确认打印</a-button>
        <a-button type="primary" @click="printItmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <!--修改订单备注model-->
    <a-modal
      :visible="updateAuditRemarkModel"
      :confirmLoading="updateAuditRemarkModelLoading"
      :width="550"
      :maskClosable="false"
      title="修改订单申请备注"
      @ok="updateAuditRemarkSubmit"
      @cancel="updateAuditRemarkModel = false"
    >
      <a-form-model layout="vertical" :model="updateAuditRemarkForm" ref="updateAuditRemarkForm">
        <a-form-model-item label="申请备注" prop="remark">
          <a-textarea v-model="updateAuditRemarkForm.remark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <a-form>
      </a-form>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { getItmsBalancePage,
ItmsFinanceFunc,
getItmsBalancePageById,
updateItmsBalance,
delItmsBalance,
getItmsBalanceFunc,
batchReviewedBalance,
batchReverReviewedBalance,
exportItmsBalanceSheet,
exportBalanceInvoices
} from '@/api/itms'
import { filterOption } from '@/utils/util'
import { getCommonNoPage, getCustomer, getCustomerInfo, getCustomerCopy, downloadExcel } from '@/api/common'
import moment from 'moment';
import { getchargeTax, listSecondInvoiceSubject, getEInvoiceAndSendEmail } from '@/api/fms'
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import debounce from 'lodash/debounce'
export default {
  name: 'ItmsBalanceBill',
  components: {
    STable,
    itmsBill,
    EditTable
  },
  data() {
    this.userInfo = this.$store.getters.userInfo
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    this.lastFetchId2 = 0;
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    return {
      balanceData: [],
      checkedBalanceList: [],
      balanceRecord: {},
      blanceFeeVisible: false,
      blanceFeeLoading: false,
      queryParam: {},
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '结算单号',
          dataIndex: 'balance_num'
        },
        {
          title: '结算状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '审核状态',
          dataIndex: 'reviewed_status',
          scopedSlots: { customRender: 'reviewedInfo' }
        },
        {
          title: '报价编号',
          dataIndex: 'quota_nums',
          width: 200,
          scopedSlots: { customRender: 'quotaNumsSlot' }
        },
        {
          title: '接单日期',
          dataIndex: 'receiving_order_dates',
          width: 150,
          scopedSlots: { customRender: 'receivingDateSlot' }
          // customRender: (text) => {
          //   if (typeof text !== 'undefined') {
          //     const names = text.map(i => {
          //       return moment(i).format('YYYY/MM/DD')
          //     })
          //     return names.join('\n')
          //   } else {
          //     return ''
          //   }
          // }
        },
        {
          title: '业务类型',
          dataIndex: 'order_type'
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '币种',
          dataIndex: 'currency_name'
        },
        {
          title: '税率',
          dataIndex: 'tax_desc'
        },
        {
          title: '备注',
          dataIndex: 'remark',
           width: 300,
          scopedSlots: { customRender: 'remarkSlot' }
        },
        {
          title: '开票费用',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'invoiceFeeItem' }
        },
        {
          title: '发票信息',
          dataIndex: 'invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '认领信息',
          dataIndex: 'fms_capital_claim_logs',
          scopedSlots: { customRender: 'fmsLogsItem' }
        },
        {
          title: '创建人',
          dataIndex: 'create_name'
        },
        {
          title: '审核人',
          dataIndex: 'reviewed_user_name'
        },
        {
          title: '审核时间',
          dataIndex: 'reviewed_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getItmsBalancePage('get_itms_balance_page', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      businessAuditFormVisible: false,
      businessAuditConfirmLoading: false,
      defaultDate: [],
      selectedRowKeys: [],
      taxRateOps: [],
      applyInvoiceVisible: false,
      applyInvoiceConfirmLoading: false,
      indeterminate: false,
      checkAll: true,
      totalAmount: '',
      selectedRows: [],
      checkedList: [],
      feeOrderList: [],
      bankAccountOps: [],
      invoiceRegOps: [],
      // 商务审核申请form
      form: {
        pay_method: '',
        // file_paper: 1,
        apply_remark: '',
        invoice_num: []
      },
      // 开票申请form
      applyInvoiceForm: {
        apply_remark: '',
        customer_name: '',
        customer_id: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        tax_rate: '',
        remark: '',
        invoice_type: '',
        invoice_subject_data: [],
        invoice_remark: '',
        many_together: 'N'
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '国贸部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      invoiceTypeList: [
        {
          value: 1, name: '专用发票'
        },
        {
          value: 2, name: '普通发票'
        },
        {
          value: 3, name: '免税发票'
        },
        {
          value: 4, name: 'TMS奥货'
        }
      ],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      orderKindOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '拖运业务', value: 5 }
      ],
      applyInvoiceRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }],
        invoice_type: [{ required: true, message: '请选择发票类型', trigger: 'change' }]
      },
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      balanceFeeIds: [],
      printItmsBillModel: false,
      printItmsBillModelLoading: false,
      printItmsBill: {
        id: 'printItmsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentData: {},
      balanceBillData: [],
      balance_id: '',
      audit_id: null,
      feeStatusMaps: {
        1: { value: 1, name: '待审核', color: '#faad14' },
        2: { value: 2, name: '业务已审核', color: '#1890ff' },
        3: { value: 3, name: '商务待审核', color: '#4cd964' },
        4: { value: 4, name: '商务审核被驳回', color: '#ff4d4f' },
        5: { value: 5, name: '待开票', color: '#ffe8e9' },
        6: { value: 6, name: '已开票', color: '#f8fadf' },
        9: { value: 9, name: '商务已审核(待付款)', color: '#e7ffec' },
        13: { value: 13, name: '已付款', color: '#e7ffec' }
      },
      customerInfo: undefined,
      fetching: false,
      customerOps: [],
      updateAuditRemarkModel: false,
      updateAuditRemarkModelLoading: false,
      updateAuditRemarkForm: {
        remark: '',
        audit_id: null
      },
      customerInfo2: undefined,
      fetching2: false,
      customerInfoOps2: [],
      // 审核状态
      reviewedStatusData: [
        { value: 0, name: '未审核' },
        { value: 1, name: '已审核' }
      ],
      totalInvoiceAmount: 0.00,
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      secondInvoiceSubjectList: [],
      invoiceSubjectData: [],
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                  if (subject.subject_name === '运输费' && subject.tax_rate === 0.09) {
                    if (!this.applyInvoiceForm.invoice_remark) {
                      this.applyInvoiceForm.invoice_remark = '转售运输服务'
                    }
                  }
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  } else {
                    this.invoiceSubjectData[index]['goods_total_tax'] = 0
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `开票备注(多票备注不相同)`,
          dataIndex: `special_remark`,
          width: 240,
          extra: { type: 'input' }
        }
      ],
      manyTogetherList: [
        { value: 'N', name: '单票' },
        { value: 'Y', name: '多票' }
      ]
    }
  },
  methods: {
    moment,
    filterOption,
    handleExportInvoices() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportBalanceInvoices({ 'ids': this.selectedRowKeys }).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    exportSheet() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportItmsBalanceSheet(this.queryParam).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    // 审核或反审核结算单
    handleReviewed(type) {
      const idList = []
      this.selectedRows.forEach(item => {
        switch (type) {
          case 'basic_audit': // 审核
            if (item.reviewed_status === 0) {
              idList.push(item.id)
            }
            break;
          case 'undo_audit': // 反审核
            if (item.reviewed_status === 1) {
              idList.push(item.id)
            }
            break;
        }
      })
      for (const item of this.selectedRows) {
        if (this.userInfo.name === '龚巍' || this.userInfo.name === '许嘉栋') {
          if (item.order_kind !== 2) {
            this.$message.error('您只能审核奥货订单~');
            return;
          }
        }
      }
      if (idList.length) {
        let fun
        if (type === 'basic_audit') {
          fun = batchReviewedBalance
        } else {
          fun = batchReverReviewedBalance
        }
        fun({ 'ids': idList }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.$refs.table.refresh(false)
          this.selectedRows = []
          this.selectedRowKeys = []
        })
      } else {
        this.$message.warning(type === 'basic_audit' ? '请选择未审核的数据' : '请选择已审核的数据')
      }
    },
    changeInvoiceType(value) {
      if (value === 3) {
        for (const item of this.taxRateOps) {
          if (item.name === '0%销项税') {
            this.applyInvoiceForm.tax_rate = item.value
          }
        }
      }
    },
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.customerInfoOps2 = [];
      this.fetching2 = true;
      getCustomerCopy({
        department: 2,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.customerInfoOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.applyInvoiceForm.customer_name = value !== undefined ? value.label : null;
      this.customerInfoOps2.forEach(item => {
        if (item.value === value.key) {
          this.applyInvoiceForm.customer_id = value.key
          this.applyInvoiceForm.tax_no = item.taxNum;
          this.applyInvoiceForm.address = item.street;
          this.applyInvoiceForm.mobile = item.phone;
          this.applyInvoiceForm.bank_card_no = item.accountInfo[0].account;
          this.applyInvoiceForm.bank = item.accountInfo[0].bank_name;
          // 选择客户,重新为银行卡赋值
          this.bankAccountOps = []
          for (let i = 0; i < item.accountInfo.length; i++) {
            this.bankAccountOps.push({
              account: item.accountInfo[i].account,
              bank_name: item.accountInfo[i].bank_name
            })
          }
        }
      });
      this.customerInfoOps2 = [];
      this.fetching2 = false;
    },
    // 修改未开票订单备注
    updateAuditRemark(record) {
      this.updateAuditRemarkForm.remark = record.remark
      this.updateAuditRemarkForm.audit_id = record.audit_id
      this.updateAuditRemarkModel = true
    },
    // 提交新的订单备注
    updateAuditRemarkSubmit() {
      getItmsBalanceFunc('update_audit_remark', this.updateAuditRemarkForm).then(res => {
        if (res) {
          this.$message.success('修改成功')
        }
        this.updateAuditRemarkForm.remark = ''
        this.updateAuditRemarkForm.audit_id = null
        this.updateAuditRemarkModel = false
        this.$refs.table.refresh(false);
      })
    },
    // 结算单
    printDebitSheet(record) {
      getItmsBalanceFunc('list_adjust_balance', { id: record.id }).then(res => {
        // 统计数据
        let charge = 0
        let pay = 0
        let profit = 0
        let originalCharge = 0
        let originalPay = 0
        let originalProfit = 0
        res.balance_item_list.forEach(v => {
          v.charge_fees &&
          v.charge_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              charge += c.amount
            } else {
              charge += c.exchange_amount
            }
          })
          v.pay_fees &&
          v.pay_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              pay += c.amount
            } else {
              pay += c.exchange_amount
            }
          })
          profit += v.profit
          v.original_charge_fees &&
          v.original_charge_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              originalCharge += c.amount
            } else {
              originalCharge += c.exchange_amount
            }
          })
          v.original_pay_fees &&
          v.original_pay_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              originalPay += c.amount
            } else {
              originalPay += c.exchange_amount
            }
          })
          originalProfit = originalCharge - originalPay
        })
        this.balanceStatic = {
          charge: '应收总计:' + charge.toFixed(2),
          pay: '应付总计:' + pay.toFixed(2),
          profit: '利润总计:' + profit.toFixed(2)
        }
        this.balanceBillData = res.balance_item_list
        this.balanceCurrency = res.currency
        this.balanceExchangeRate = res.exchange_rate
        this.currentData.balanceExchangeRate = res.exchange_rate
        this.currentData.balanceStatic = this.balanceStatic
        this.currentData.currency = res.currency
        this.currentData.customer = record.customer_name
        this.currentData.record_no = record.balance_num
        this.currentData.applicant_name = record.create_name
        this.currentData.apply_remark = record.remark
        this.currentData.charge_total = charge;
        this.currentData.pay_total = pay;
        this.currentData.profit = profit;
        this.currentData.original_charge_total = originalCharge;
        this.currentData.original_pay_total = originalPay;
        this.currentData.original_profit = originalProfit;
        this.currentData.reviewed_user_name = record.reviewed_user_name
        this.currentData.adjust_orders = res.adjust_orders
      }).finally(() => {
        this.printItmsBillModel = true
      })
    },
    balanceFeeChange(value) {
      this.balanceFeeIds = value
    },
    delBalanceFee(index, type) {
      if (type === 'charge') {
        this.balanceRecord.charge_fees.splice(index, 1)
      } else {
        this.balanceRecord.pay_fees.splice(index, 1)
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.start_date = null
        this.queryParam.end_date = null
      }
    },
    reviewedDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['reviewed_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['reviewed_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['reviewed_start_date'] = null
        this.queryParam['reviewed_end_date'] = null
      }
    },
    openDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['open_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['open_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['open_start_date'] = null
        this.queryParam['open_end_date'] = null
      }
    },
    // 编辑结算单
    handleEdit(record) {
      this.balanceRecord = JSON.parse(JSON.stringify(record))
      this.balanceFeeIds = []
      getItmsBalancePageById('get_itms_fees_by_balance_id', { 'balanceId': record.id }).then(res => {
        this.balanceData = res
      }).finally(() => {
        this.balanceRecord.quotaNumArr = this.balanceRecord.quota_nums.join(',')
        this.blanceFeeVisible = true
      })
    },
    // 删除结算单
    handleDelete(type, record) {
      this.$confirm({
        title: `确认提示`,
        content: `确定要删除？`,
        onOk: () => {
          delItmsBalance('delete_itms_balance', { 'id': record.id }).then(res => {
            this.$notification['success']({
              message: `提示`,
              description: `删除成功`
            });
          }).finally(() => {
            this.$refs.table.refresh(false);
          });
        }
      });
    },
    // 结算单编辑
    blanceFeeSubmit() {
      const feeIds = []
      this.balanceRecord.pay_fees.forEach(data => {
        feeIds.push(data.id)
      })
      const data = {
        'fee_ids': feeIds,
        'id': this.balanceRecord.id,
        'balance_fee_ids': this.balanceFeeIds
      }
      updateItmsBalance('update_itms_balance', data).then(res => {
        this.$message.success('编辑结算单成功')
        this.balanceFeeIds = []
        this.$refs.table.refresh(false);
      }).finally(() => {
        this.blanceFeeVisible = false
      })
    },
    blanceFeeCancel() {
      this.balanceFeeIds = []
      this.blanceFeeVisible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        for (const item of changeRows) {
          this.selectedRows.push(item)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    handleBillApply(record) {
      this.checkedList = []
      const recordRow = [record]
      this.totalAmount = ''
      let flag = false
      const feeMaps = []
      const map = new Map()
      const feeNameMap = new Map()
      recordRow.forEach(row => {
        const orderFee = {
          quotaNum: row.quota_nums,
          feeList:
            row.charge_fees &&
            row.charge_fees
              .filter(e => e.fee_status === 2 || e.fee_status === 4)
              .map(e => {
                if (map.has(e.currency_name)) {
                  map.set(e.currency_name, e.money + map.get(e.currency_name))
                } else {
                  map.set(e.currency_name, e.money)
                }
                if (feeNameMap.has(e.fee_name)) {
                  feeNameMap.set(e.fee_name, e.money + feeNameMap.get(e.fee_name))
                } else {
                  feeNameMap.set(e.fee_name, e.money)
                }
                if (e.fee_status === 2 || e.fee_status === 4) {
                  flag = true
                }
                this.checkedList.push(e.id)
                return {
                  id: e.id,
                  feeName: e.fee_name,
                  money: e.money,
                  currency_name: e.currency_name
                }
              })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.bankAccountOps = []
        this.getCustomerOps(recordRow[0].customer_name, recordRow[0].customer_id)
        this.bizAuditForm = {
          ...this.bizAuditForm,
          customer: recordRow[0].customer_name,
          fees: feeMaps,
          creator: recordRow[0].create_name
        }
        this.balance_id = recordRow[0].id
        this.audit_id = recordRow[0].audit_id
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        this.totalAmount += '；'
        for (const key of feeNameMap.keys()) {
          this.totalAmount += key + ':' + feeNameMap.get(key).toFixed(2) + ','
        }
        this.applyInvoiceForm.apply_remark = record.invoice_remark_info
        this.applyInvoiceVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    // 提交开票
    handleApplyFormSubmit() {
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          const data = {
            ...this.applyInvoiceForm,
            id_list: this.checkedList,
            balance_id: this.balance_id,
            audit_id: this.audit_id
          };
          this.applyInvoiceConfirmLoading = true
          ItmsFinanceFunc('apply_ticket', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
            this.totalAmount = '';
            this.applyInvoiceVisible = false;
            this.$refs.table.refresh(false);
          }).finally(() => {
            this.applyInvoiceConfirmLoading = false
          })
        }
      })
    },
    handleApplyBizAudit(record) {
      // 提交商务审核
      this.checkedList = []
      const recordRow = [record]
      const feeMaps = []
      let flag = false
      const map = new Map()
      recordRow.forEach(v => {
        const orderFee = {
          quotaNum: v.quota_nums,
          feeList: v.pay_fees && v.pay_fees
            .filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              if (map.has(e.currency_name)) {
                map.set(e.currency_name, e.money + map.get(e.currency_name))
              } else {
                map.set(e.currency_name, e.money)
              }
              // total += e.money ? e.money : 0
              if (e.fee_status === 2 || e.fee_status === 4) {
                flag = true
              }
              this.checkedList.push(e.id)
              return {
                id: e.id,
                feeName: e.fee_name,
                money: e.money,
                currency_name: e.currency_name,
                pay_target_name: e.pay_target_name
              }
            })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.feeOrderList = feeMaps
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        this.businessAuditFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    billOrVerifySubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          ItmsFinanceFunc('business_audit', {
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            apply_remark: this.form.apply_remark,
            // file_paper: this.form.file_paper,
            id_list: this.checkedList
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.form.pay_method = ''
            // this.form.file_paper = 1
            this.form.apply_remark = ''
            this.form.invoice_num = []
            this.businessAuditConfirmLoading = false;
            this.businessAuditFormVisible = false;
            this.clearSelected();
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceVisible = false;
      this.totalAmount = '';
      this.invoiceSubjectData = []
      this.applyInvoiceForm.invoice_subject_data = []
    },
    billOrverifyCancel() {
      this.businessAuditFormVisible = false;
      this.totalAmount = ''; // 每次关闭弹出框后将会将总金额进行重置
    },
    getCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.applyInvoiceForm.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.applyInvoiceForm.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.applyInvoiceForm.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.applyInvoiceForm.address = typeof data.street !== 'undefined' ? data.street : ''
          this.applyInvoiceForm.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.applyInvoiceForm.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.applyInvoiceForm.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.applyInvoiceForm.bank = item.bank_name
        }
      })
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    // 获得委托客户
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 2,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam['customer_id'] = null
        this.customerOps = []
        this.fetching = false
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    },
    downloadInvoiceFile(item) {
      getEInvoiceAndSendEmail({ 'invoice_no': item.invoice_no, 'is_business': '1' }).then(v => {
        item.is_download = '1';
        if (v) {
          window.open(v, true);
        }
      })
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    listSecondInvoiceSubject({ department: 2 }).then(v => {
      this.secondInvoiceSubjectList = v
    })
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
